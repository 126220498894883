
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import YesNo from "@/components/YesNo.vue";

    @Component({
        name: "RoundRadio",
        components: {YesNo}
    })
    export default class RoundRadio extends Vue {
        @Prop(Boolean) yesno!: boolean;
        @Prop() label!: string;
        @Prop() selected!: boolean;
        @Prop(Boolean) disabled!: boolean;
    }
