
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";

@Component({
    name: "YesNo"
})
export default class YesNo extends mixins(VModel) {

}
