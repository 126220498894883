
import {Component, Vue, Watch} from 'vue-property-decorator';
import ViewConfigurator from "@/components/ViewConfigurator.vue";
import {Action, Getter, State} from "vuex-class";
import {IAgencyDB, IBusinessSectorDB, ICompany, ICompanyDB, IUserDB, LabelValue, StoreAction} from "@/types";
import ClientForm from "@/components/client/ClientForm.vue";
import moment from "moment-timezone";
import {ROUTES} from "@/data";
import Visualisations from "@/components/Visualisations.vue";
import ClientActions from "@/components/client/ClientActions.vue";
import {capitalize} from "@/helpers/commons";
import AgencyPreview from "@/components/AgencyPreview.vue";
import {mixins} from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";

@Component({
    name: "Agencies",
    components: {AgencyPreview, Visualisations, ViewConfigurator}
})
export default class Agencies extends mixins(PageMixin) {
    $refs!: {
        main: any
    };
    agencies:IAgencyDB[] = [];
    loading = false;
    errors:any = {};
    mode = 'list';
    timeout:any = 0;
    totalRecords = 0;
    filters: any = {};
    columns: any = [];
    viewFilters: any = {
        zip: "",
        jobId: "",
        hasVehicle: "undefined",
        hasNightAvailable: "undefined",
        hasWeekendAvailable: "undefined"
    };
    agencyToRead:any = null;
    agencyLoading = true;

    visualisations:any= {
        mode: 'list'
    };

    @Action('actions/loadAgencies') loadAgencies!: StoreAction;
    @Action('actions/loadAgencyFromCompany') loadAgencyFromCompany!: StoreAction;
    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];

    @State('selectedCollaborators') selectedCollaborators!: IUserDB[];
    @State('selectedDomains') selectedDomains!: IBusinessSectorDB[];

    @Watch('selectedCollaborators', {deep: true})
    onCollabs() {
        this.timedSearch();
    }

    @Watch('selectedDomains', {deep: true})
    onDomains() {
        this.timedSearch();
    }

    @Watch('viewFilters', {deep: true})
    onViewFilters() {
        this.timedSearch();
    }

    @Watch('visualisations', {deep: true})
    onVisu() {
        this.timedSearch();
    }

    get listMode() {
        return this.mode === 'list';
    }

    get headers() {
        return [
            {
                field: "_id",
                hidden: true
            },
            ...this.columns.map((column:any) => {
                return {
                    label: this.$t('page.agencies.table.' + column.id),
                    field: column.id,
                    sortable: column.sortable,
                    formatFn: this.formatCell(column.id),
                    filterOptions: {
                        enable: column.filterable,
                    }
                }
            }),
            // {
            //     field: "__actions",
            //     sortable: false,
            // }
        ];
    }

    get rows() {
        return this.agencies.map(agency => {
            // Backend should return IUserDB instead of string
            const collaborators: any = agency.collaborators;
            const collaborator = collaborators?.split(", ") || '';
            return {
                ...agency,
                name: capitalize(agency.name) || '',
                collaborators: collaborator.map((c: any) => {
                    console.log(c)
                    return c.split(" ")
                            .map((n: any) => capitalize(n))
                            .join(' ');
                    }).join(", ")
            }
        });
    }

    timedSearch() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init();
        }, 300);
    }

    formatCell(key: string) {
        return (value: any) => {
            if (Array.isArray(value)) {
                return value.join(', ');
            }
            if (typeof value === "boolean") {
                return value ? this.$t('global.yes') : this.$t('global.no');
            }
            if (typeof value === "number" && key === "lastMission") {
                return moment(value).format(<string>this.$t('date.format'));
            }
            if (key === "name") {
                return value;
            }
            return value;
        }
    }

    closeTooltip() {
        this.$refs.main.$el.click();
    }

    onModifier(modifiers: any) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.init(modifiers);
        }, 300);
    }

    lineClicked(row:any) {
        this.openAgencyToRead(row.row._id);
    }

    get formattedViewFilters() {
        return {
            search: this.viewFilters.search || undefined,
        }
    }

    async openAgencyToRead(agencyId:string) {
        this.agencyLoading = true;
        this.agencyToRead = true; // just to display component with loading
        try {
            this.agencyToRead = await this.loadAgencyFromCompany(agencyId);
        } catch (e) {
            console.log(e);
            this.agencyToRead = null;
        }
        this.agencyLoading = false;
    }

    async init(modifiers?: any) {
        this.loading = true;
        try {
            if (modifiers) {
                this.filters = {
                    offset: (modifiers.currentPage - 1) * modifiers.currentPerPage,
                    limit: modifiers.currentPerPage,
                    sort: modifiers.sort,
                    columnFilters: modifiers.columnFilters && ({
                        ...modifiers.columnFilters,
                    }) || undefined,
                };

                if (this.filters.columnFilters?.businessSector) {
                    delete this.filters.columnFilters.businessSector;
                }
            }
            this.filters.viewFilters = this.formattedViewFilters;
            this.filters.view = this.visualisations.view;
            this.filters.mode = this.visualisations.mode;

            const result:any = await this.loadAgencies(this.filters);

            this.columns = result.columns;
            this.agencies = result.rows || [];
            this.totalRecords = result.pagination?.total;
        } catch (e) {
            this.errors = e;
            console.log(e);
        }
        this.loading = false;
    }

    beforeMount() {
        this.init({
            currentPage: 1,
            currentPerPage: 50
        });
    }
}
