"use strict";

export function showModal(this: any, modalName: string) {
    this['show' + modalName] = true;

    this.$nextTick(() => {
        this.$modal.show(modalName);
    });
}


export function hideModal(this: any, modalName: string) {
    this['show' + modalName] = true;

    this.$nextTick(() => {
        this.$modal.hide(modalName);
    });
}

export async function ajax(this: any, action: string, data: any, contextError?: string, contextGlobalError?: string, contextLoading?: string) {
    const loadingString = contextLoading ? contextLoading + 'Loading' : 'loading';
    const errorString = contextError ? contextError + 'Errors' : 'errors';
    const errorGlobalString = contextGlobalError ? contextGlobalError + 'GlobalErrors' : 'globalErrors';

    this[loadingString] = true;
    this[errorString] = {};
    this[errorGlobalString] = {};
    try {
        this[loadingString] = false;
        return await this[action](data);
    }
    catch (e) {
        this[errorString] = e.errors;
        this[errorGlobalString] = e._global;

        this[loadingString] = false;
        throw(e);
    }
}

// export async function simpleAjax(this: any, action: string, data: any) {
//     try {
//         return await this[action](data);
//     } catch (e) {
//         throw(e);
//     }
// }
//
