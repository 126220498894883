
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import VModel from "@/mixins/VModel.vue";
    import {ROUTES} from "@/data";
    import moment, {Moment} from "moment-timezone";
    import clonedeep from "lodash.clonedeep";
    import {capitalize} from "@/helpers/commons";
    import {Getter, State} from "vuex-class";

    @Component({
        name: "Visualisations"
    })
    export default class Visualisations extends mixins(VModel) {
        @Prop(Boolean) isQueryGantt!: boolean;

        $refs!: any;
        data:any = {
            interims: ['list', 'grid', 'map'],
            jobs: ['list'],
            businessSectors: ['list'],
            clients: ['list', 'grid', 'map']
        };
        opened = false;
        timeout:any = 0;

        capitalize = capitalize;

        @State('selectedView') selectedView!: any;
        @Getter('isAgency') isAgency!: boolean;
        @Getter('isCompany') isCompany!: boolean;

        @Watch('innerValue.view')
        onView() {
            if (this.data[this.innerValue.view]?.indexOf(this.innerValue.mode) === -1) {
                if (this.isCompany && this.isInterimList) {
                    this.innerValue.mode = "gantt-workers";
                } else {
                    this.innerValue.mode = this.data[this.innerValue.view][0];
                }
            }
        }

        get isArchives() {
            return this.$route.name === 'Archives'
        }

        get hasWeekRanges() {
            return this.isWeekMode
                || this.isGantMode
                || this.isInterimsPlanning
                || this.isComingInterimsMode;
        }

        get ranges() {
            const ranges = [];
            const context = this.context;
            let i = 0;
            let newFrom, newTo, fromDate, toDate, limit;

            if (this.hasDay) {
                fromDate = moment(this.innerValue.dateRange.from).subtract(10, 'days').startOf('days');
                toDate = moment(this.innerValue.dateRange.to).subtract(10, 'days').endOf('days');
                limit = 28;
            }
            else if (this.hasWeekRanges) {
                fromDate = moment(this.innerValue.dateRange.from).subtract(10, 'week').startOf('week');
                toDate = moment(this.innerValue.dateRange.to).subtract(10, 'week').endOf('week');
                limit = 20;
            } else {
                fromDate = moment(this.innerValue.dateRange.from).subtract(6, 'month').startOf('month');
                toDate = moment(this.innerValue.dateRange.to).subtract(6, 'month').endOf('month');
                limit = 12;
            }

            while (i <= limit) {
                newFrom = fromDate.clone().add(i, context).startOf(context);
                newTo = toDate.clone().add(i, context).endOf(context);
                ranges.push({
                    name: this.formattedRange({from: newFrom, to: newTo}),
                    from: newFrom,
                    to: newTo
                });
                ++i;
            }

            return ranges;
        }

        get theRange() {
            const fromDate = moment(this.innerValue.dateRange.from);
            const toDate = moment(this.innerValue.dateRange.to);

            return this.formattedRange({from: fromDate, to: toDate});
        }

        get isClientList() {
            return this.$route.name === ROUTES.APP.CLIENTS;
        }
        get isInterimList() {
            return this.$route.name === ROUTES.APP.INTERIMS_LIST
                    || this.$route.name === ROUTES.APP.INTERIMS_INVITATIONS;
        }
        get isAgencyList() {
            return this.$route.name === ROUTES.APP.AGENCIES;
        }
        get isFollowing() {
            return this.$route.name === ROUTES.APP.FOLLOWING
                || ROUTES.APP.FOLLOWING_ARCHIVE
                || ROUTES.APP.FOLLOWING_GLOBAL;
        }
        get isPlanning() {
            return this.$route.name === ROUTES.APP.PLANNING
                || this.$route.name === ROUTES.APP.PLANNING_ORDER
                || this.$route.name === ROUTES.APP.PLANNING_WORKERS
                || this.$route.name === ROUTES.APP.PLANNING_COMPANIES;
        }

        get isAtsPlanning() {
            return this.$route.name === ROUTES.ATS.FOLLOWING.PLANNING;
        }

        get isAtsmodal() {
            return this.$route.name === ROUTES.ATS.FOLLOWING.GLOBAL || this.$route.name === ROUTES.ATS.CANDIDATE._ROOT;
        }

        get isStatistics() {
            return this.$route.name === ROUTES.APP.STATISTICS;
        }

        get hasDateRange() {
            return this.isArchives
                || this.isPlanning
                || this.isAtsPlanning
                || this.isAtsmodal
                || this.isInterimsPlanning
                || (this.isQueryGantt && this.isInterimList)
                || (this.isCompany && this.isInterimList);
        }


        get isDayMode() {
            return this.innerValue?.mode === 'day'
        }

        get isWeekMode() {
            return this.innerValue?.mode === 'week';
        }

        get isAnyMode() {
            return this.innerValue?.mode === 'any';
        }

        get isMonthMode() {
            return this.innerValue?.mode === 'month';
        }

        get isGantMode() {
            return this.isGanttWorkers || this.isGanttClients;
        }

        get isGanttWorkers() {
            return this.innerValue?.mode === 'gantt-workers';
        }

        get isGanttClients() {
            return this.innerValue?.mode === 'gantt-clients';
        }

        get isComingInterimsMode() {
            return this.innerValue?.mode === 'comingInterims';
        }

        get isInterimsPlanning() {
            return this.$route.query?.mode === 'workers-availabilities';
        }

        get isGestionsInactifs() {
            return this.$route.query?.mode === "workers-to-be-inactive";
        }

        get hasDay() {
            return this.isAtsPlanning;
        }
        get hasWeek() {
            return !this.isGanttWorkers && (this.isPlanning || this.isAtsPlanning);
        }
        get hasMonth() {
            return !this.isGanttWorkers && (this.isPlanning || this.isAtsPlanning);
        }
        get hasGant() {
            if (this.isCompany && this.isInterimList) {
                return true;
            }
            if (this.isAgency && this.isInterimList && this.isQueryGantt) {
                return true;
            }
            return this.isAgency && this.isPlanning;
        }
        get hasComingInterims() {
            return this.isCompany && this.isPlanning;
        }
        get hasClient() {
            return this.isClientList;
        }
        get hasInterim() {
            if (this.isCompany && this.isInterimList) {
                return false;
            }
            if (this.isAgency && this.isInterimsPlanning || this.isGestionsInactifs) {
                return false;
            }
            return !this.isQueryGantt && this.isInterimList;
        }
        get hasDomains() {
            return false;
        }
        get hasJobs() {
            if (this.isCompany && this.isInterimList) {
                return false;
            }
            if (this.isAgency && this.isInterimsPlanning || this.isGestionsInactifs) {
                return false;
            }
            return !this.isQueryGantt && this.isInterimList;
        }

        get hasGridActive() {
            return this.isClientList || this.isInterimList;
        }

        get hasJobsActive() {
            return this.isInterimList;
        }

        get hasMapActive() {
            return this.isClientList || this.isInterimList;
        }

        get hasList() {
            if (this.isCompany && this.isInterimList) {
                return false;
            }
            if (this.isAgency && this.isInterimsPlanning || this.isGestionsInactifs) {
                return false;
            }
            return this.innerValue.view === 'clients'
                || this.innerValue.view === 'interims'
                || this.innerValue.view === 'domains'
                || this.innerValue.view === 'jobs';
        }
        get hasGrid() {
            if (this.isCompany && this.isInterimList) {
                return false;
            }
            if (this.isAgencyList || this.isGestionsInactifs) {
                return false;
            }
            if (this.isAgency && this.isInterimsPlanning) {
                return false;
            }
            return this.innerValue.view === 'clients'
                || this.innerValue.view === 'interims';
        }
        get hasPlanning() {
            if (this.isCompany && this.isInterimList) {
                return false;
            }
            if (this.isAgencyList) {
                return false;
            }
            if (this.isAgency && this.isInterimsPlanning) {
                return false;
            }
            return this.innerValue.view === 'clients'
                || this.innerValue.view === 'interims'
                || this.innerValue.view === 'domains'
                || this.innerValue.view === 'jobs';
        }
        get hasMap() {
            if (this.isCompany && this.isInterimList) {
                return false;
            }
            if (this.isAgencyList || this.isGestionsInactifs) {
                return false;
            }
            if (this.isAgency && this.isInterimsPlanning) {
                return false;
            }

            return this.innerValue.view === 'clients'
                || this.innerValue.view === 'interims';
        }

        get hasDisplayableViews() {
            return this.hasDateRange || this.hasClient || this.hasInterim || this.hasJobs || this.hasDomains || this.isAnyMode;
        }

        get context() {
            const context = this.hasWeekRanges ? 'week' : 'month';
            return this.isDayMode ? 'days' : context
        }

        formattedRange(range:any) {
            if (this.hasWeekRanges) {
                return `${this.$t('page.planning.week', {week: range.from.week()})} : ${range.from.format('DD MMMM')} - ${range.to.format('DD MMMM YY')}`;
            }
            else if (this.isDayMode) {
                return moment(Date.now()).format('dddd DD MMMM');
            }
            else {
                return `<div class="month">${capitalize(range.from.format('MMMM'))}</div><div class="year">${capitalize(range.from.format('YYYY'))}</div>`;
            }
        }

        subDate() {
            this.innerValue.dateRange.from = moment(this.innerValue.dateRange.from).subtract(1, this.context).startOf(this.context).valueOf();
            this.innerValue.dateRange.to = moment(this.innerValue.dateRange.to).subtract(1, this.context).endOf(this.context).valueOf();
            this.$emit('dateChange', this.innerValue.dateRange);
        }

        addDate() {
            this.innerValue.dateRange.from = moment(this.innerValue.dateRange.from).add(1, this.context).startOf(this.context).valueOf();
            this.innerValue.dateRange.to = moment(this.innerValue.dateRange.to).add(1, this.context).endOf(this.context).valueOf();
            this.$emit('dateChange', this.innerValue.dateRange);
        }

        selectRange(range:any) {
            this.innerValue.dateRange.from = range.from;
            this.innerValue.dateRange.to = range.to;
            this.opened = false;
        }

        isSelectedRange(range:any) {
            return range.name === this.theRange;
        }

        showRanges() {
            this.opened = true;
            if (!this.timeout) {
                this.timeout = setTimeout(() => {
                    this.$scrollTo(this.$refs['range-10'][0], 300, {
                        container: this.$refs.container.$el,
                        offset: -((this.$refs.container.$el.parentElement.scrollHeight / 2) - 20)
                    });
                    this.timeout = 0;
                }, 50);
            }
        }

        hideRanges() {
            this.opened = false;
            clearTimeout(this.timeout);
        }

        selectGant() {
            if (this.isQueryGantt) {
                this.innerValue.mode = this.$route.query.mode;
            } else {
                if (this.isCompany && this.isInterimList) {
                    this.innerValue.mode = "gantt-workers";
                } else {
                    this.innerValue.mode = "gantt-clients";
                }
            }
        }

        onViewModeSelect(viewMode: string) {
            this.$emit('displayChange', viewMode);
        }
    }
