
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {IAgencyDB, IBusinessSectorDB, IGeoLocation, IUserDB, LabelValue} from "@/types";
import InlineField from "@/components/InlineField.vue";
import {capitalize, formattedAddress} from "@/helpers/commons";
import NumberTile from "@/components/tileContent/NumberTile.vue";
import {ROUTES} from "@/data";
import ContactLine from "@/components/ContactLine.vue";
import {Getter, State} from "vuex-class";
import ProfileContacts from "@/components/profileContexts/ProfileContacts.vue";
import ProfileDescription from "@/components/profileContexts/ProfileDescription.vue";
import ProfileMap from "@/components/profileContexts/ProfileMap.vue";
import ProfileVideo from "@/components/profileContexts/ProfileVideo.vue";

@Component({
    name: "AgencyRead",
    components: {ProfileVideo, ProfileMap, ProfileDescription, ProfileContacts, ContactLine, NumberTile, InlineField}
})
export default class AgencyRead extends Vue {
    $refs!: {
        any1: any,
        any2: any,
        any3: any,
        any4: any
    };

    @Prop() agency!: IAgencyDB;
    @State('collaborators') collaborators!: IUserDB[];
    @State('configuration') configuration!: any;
    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('getJob') getJob!: any;
    @Getter('isAgency') isAgency!: any;
    @Getter('isCompany') isCompany!: any;
    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];

    capitalize = capitalize;

    lines:any = [];
    descriptionSize = "";
    mapSize = "";
    apiUrl: string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/";

    get logo() {
        if (this.agency.logo?.indexOf('base64') !== -1) {
            return this.agency.logo;
        }
        return this.apiUrl + this.agency.logo
    }

    center: IGeoLocation = {lat: 48.845617, lng: 2.2431133};

    get zoom() {
        return (this.mapMarkerPosition && 16) || 8;
    }

    get mapMarkerPosition() {
        if (this.agency?.address?.location) {
            return {
                lat: parseFloat(this.agency.address.location[1].toString()),
                lng: parseFloat(this.agency.address.location[0].toString())
            }
        }
    }

    get agencyAddress() {
        return formattedAddress(this.agency.address);
    }

    get theDescription() {
        return this.agency.description?.replace(/\n/gm, '<br>');
    }

    get agencyName() {
        return this.agency.name;
    }

    get youtubeId() {
        if (this.agency.video) {
            if (this.agency.video.indexOf('youtube.com') !== -1) {
                if (this.agency.video.indexOf("v=") !== -1) {
                    return this.agency.video.split('v=')[1];
                }
                if (this.agency.video.indexOf("/embed/") !== -1) {
                    return this.agency.video.split('/embed/')[1];
                }
            }
            if (this.agency.video.indexOf('youtu.be') !== -1) {
                return this.agency.video.split('youtu.be/')[1]
            }
        }
    }

    get socialNetworks() {
        return this.agency.socialNetworks || {};
    }

    get youtubeLink() {
        return this.agency.video && ("https://www.youtube.com/embed/" + this.youtubeId);
    }

    get businessSectors() {
        const uniq:any = {};

        if (this.agency?.survey?.developingBussinessSectors?.length) {
            this.agency.survey.developingBussinessSectors.forEach((mbs:any) => {
                if (mbs?.value) {
                    uniq[mbs.value] = mbs;
                }
            });
        }
        if (this.agency?.survey?.mainBusinessSectors?.length) {
            this.agency.survey.mainBusinessSectors.forEach((mbs:any) => {
                if (mbs?.value) {
                    uniq[mbs.value] = mbs;
                }
            });
        }

        return Object.keys(uniq).map((k:string) => uniq[k]) || [];
    }

    getPictogram(businessSectorId: string) {
        let f = this.configuration.businessSectors.find((bs: IBusinessSectorDB) => bs._id === businessSectorId);

        return f?.pictogram;
    }

    setSize(r:any) {
        if (r.$refs?.map) {
            this.mapSize = r.$refs.map.$el.scrollHeight + 'px';
        }
        if (r.$refs?.description) {
            this.descriptionSize = r.$refs.description.$el.scrollHeight + 'px';
        }
    }

    setDescriptionSize() {
        if (this.agency) {
            if (this.$refs.any1) {
                this.setSize(this.$refs.any1);
            }
            if (this.$refs.any2) {
                this.setSize(this.$refs.any2);
            }
            if (this.$refs.any3) {
                this.setSize(this.$refs.any3);
            }
            if (this.$refs.any4) {
                this.setSize(this.$refs.any4);
            }
        }
    }

    externalLink(link: string) {
        if (link) {
            if (link.indexOf('http://') === -1) {
                link = "http://" + link;
            }
            window.open(link, "_blank");
        }
    }

    setLines() {
        let lineOne:any = [];
        let lineTwo:any = [];
        let availablesObjects:string[] = [];

        if ((this.isAgency && this.collaborators?.length) || (this.isCompany && this.agency?.collaborators?.length)) {
            availablesObjects.push("Contacts")
        }
        if (this.theDescription) {
            availablesObjects.push("Description")
        }
        if (this.mapMarkerPosition) {
            availablesObjects.push("Map")
        }
        if (this.youtubeLink) {
            availablesObjects.push("Video")
        }

        availablesObjects.forEach((a:any, i:number) => {
            if (i > 1) {
                lineTwo.push(a);
            } else {
                lineOne.push(a);
            }
        });

        this.lines = [lineOne, lineTwo];
    }

    mounted() {
        setTimeout(() => {
            this.setDescriptionSize();
        })
        if (this.agency?.survey?.developingBussinessSectors?.length) {
            this.agency.survey.developingBussinessSectors = this.agency.survey.developingBussinessSectors.map((bs:any) => {
                if (typeof bs === "string") {
                    bs = this.businessSectorOptions.find((bso:any) => bso.value === bs);
                }
                return bs;
            })
        }
        if (this.agency?.survey?.mainBusinessSectors?.length) {
            this.agency.survey.mainBusinessSectors = this.agency.survey.mainBusinessSectors.map((bs:any) => {
                if (typeof bs === "string") {
                    bs = this.businessSectorOptions.find((bso:any) => bso.value === bs);
                }
                return bs;
            })
        }
        this.setLines();
    }
}
