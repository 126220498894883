
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import clonedeep from "lodash.clonedeep";
    import RoundRadio from "@/components/RoundRadio.vue";

    @Component({
        name: "RoundRadios",
        components: {RoundRadio}
    })
    export default class RoundRadios extends Vue {
        @Prop(Boolean) readonly oneSelected!: boolean;
        @Prop(Array) readonly alreadySelected!: string[];
        @Prop(Array) readonly items!: any;
        @Prop(Function) readonly label!: (item:any) => string;
        @Prop(Array) readonly exceptions!: string[];
        selected: string[] = [];

        isException(id: string) {
            return this.exceptions && (this.exceptions.indexOf(id) !== -1);
        }

        check(item: any) {
            const pos:number = this.selected.indexOf(item._id);

            if (this.isException(item._id)) {
                if (pos !== -1) {
                    if (!this.oneSelected) {
                        this.selected = [];
                    }
                } else {
                    this.selected = [item._id];
                }
            } else {
                this.exceptions?.forEach((exception: string) => {
                    const expos:number = this.selected.indexOf(exception);
                    if (expos !== -1) {
                        this.selected.splice(expos, 1);
                    }
                });

                if (pos !== -1) {
                    if (this.oneSelected && this.selected.length === 1) {
                    } else {
                        this.selected.splice(pos, 1);
                    }
                } else {
                    this.selected.push(item._id);
                }
            }

            this.$emit('onSelect', this.selected);
        }

        isSelected(item: any) {
            return this.selected.indexOf(item._id) !== -1;
        }

        beforeMount() {
            if (this.alreadySelected) {
                this.selected = clonedeep(this.alreadySelected);
                this.$emit('onSelect', this.selected);
            }
        }
    }
