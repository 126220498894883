
import {Component, Vue, Prop} from 'vue-property-decorator';
import AgencyRead from "@/components/agency/AgencyRead.vue";
import {IAgencyDB} from "@/types";

@Component({
    name: "AgencyPreview",
    components: {AgencyRead}
})
export default class AgencyPreview extends Vue {
    @Prop() agency!: IAgencyDB;
    @Prop() loading!: boolean | undefined;

    get hasLoading() {
        return typeof this.loading === "boolean";
    }
}
